<template>
  <div class="chessHand"
       style="padding-bottom: 20px">
    <div v-title
         data-title="节日首存"></div>

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-electronicrenew.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);"
                    v-if="datalist">{{ datalist.act_time }}</span>
            </div>
            <div class="title-item">
              <p>领取时间:</p>
              <span style="color:var(--li-colortext);"
                    v-if="datalist">{{ datalist.act_time_cn }}</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">请在活动页面选择参加活动的类型以及场馆，系统根据当笔存款金额为申请金额参加活动。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  游戏类型
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  体育游戏馆
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  棋牌游戏馆
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  真人游戏馆
                </th>
              </tr>
            </thead>
            <tbody v-if="datalist">
              <tr>
                <td>最低存款金额</td>
                <td v-if="datalist.tconfig">{{ datalist.tconfig.dpt_money }}</td>
                <td v-if="datalist.qconfig">{{ datalist.qconfig.dpt_money }}</td>
                <td v-if="datalist.zconfig">{{ datalist.zconfig.dpt_money }}</td>
              </tr>
              <tr>
                <td>红利比例</td>
                <td v-if="datalist.tconfig">{{ datalist.tconfig.bonus_rate }}%</td>
                <td v-if="datalist.qconfig">{{ datalist.qconfig.bonus_rate }}%</td>
                <td v-if="datalist.zconfig">{{ datalist.zconfig.bonus_rate }}%</td>
              </tr>
              <tr>
                <td>最高彩金</td>
                <td v-if="datalist.tconfig">{{ datalist.tconfig.max_bonus }}</td>
                <td v-if="datalist.qconfig">{{ datalist.qconfig.max_bonus }}</td>
                <td v-if="datalist.zconfig">{{ datalist.zconfig.max_bonus }}</td>
              </tr>
              <tr>
                <td>流水限制</td>
                <td v-if="datalist.tconfig">{{ datalist.tconfig.beat_limit }}</td>
                <td v-if="datalist.qconfig">{{ datalist.qconfig.beat_limit }}</td>
                <td v-if="datalist.zconfig">{{ datalist.zconfig.beat_limit }}</td>
              </tr>
            </tbody>
          </table>

          <div style="color:#3982fe;font-size:14px;margin: 15px 10px 10px 15px;text-align: center;font-weight: 600;">
            请选择领取的游戏类型
          </div>
          <div class="tab-box">
            <div class="tab-index"
                 v-for="(item, index) in tabs"
                 :key="index"
                 @click="changetabindex(index, item.act)"
                 :class="index == tabindex ? 'tabact' : ''">
              {{ item.text }}
            </div>
          </div>

          <p v-if="datalist.tconfig"
             style="font-family: PingFang SC;font-weight: bold;color: #e93f3f;font-size: 12px;padding-bottom: 15px;margin-top: 10px;text-align: center;line-height: 18px;">
            *游戏馆各流水倍数：体育{{ datalist.tconfig.beat_limit }}倍、棋牌{{ datalist.qconfig.beat_limit }}倍、真人{{
          datalist.zconfig.beat_limit }}倍
          </p>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  当前等级
                </th>
                <th style="color: #fff;">
                  当前存款
                </th>
                <th style="color: #fff;">
                  可得彩金
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  流水要求
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datalist.now_level }}</td>
                <!-- <td>{{ datalist.szbonus_water }}</td> -->
                <td>{{ datalist.money }}</td>

                <td>
                  {{
                tabindex == 0
                ? datalist.now_bonus
                : tabindex == 2
                  ? datalist.now_bonus3
                  : tabindex == 1
                    ? datalist.now_bonus2
                    : tabindex == 3
                      ? datalist.now_bonus1
                      : 0
              }}
                </td>
                <!-- now_limit_amount 体育  1真人  2棋牌  3电竞 -->
                <td v-if="datalist">
                  {{
                tabindex == 0
                ? datalist.now_limit_amount
                : tabindex == 2
                  ? datalist.now_limit_amount3
                  : tabindex == 1
                    ? datalist.now_limit_amount2
                    : tabindex == 3
                      ? datalist.now_limit_amount1
                      : 0
              }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                每位贝宝会员在活动时间内都可选择相对应的游戏馆申请1次彩金，活动有效投注计算公式：<br />
                (充值金额+彩金)×流水倍数。
              </li>
              <li>
                <span>2.</span>
                本次活动为唯一性活动，每位贝宝的会员只能申请领取一次，且本活动仅和返水优惠共享，不与其他任何优惠共享;
              </li>
              <li>
                <span>3.</span>
                该优惠活动成功申请后不能取消，需完成活动指定流水要求或申请该活动的账户低于0元时才能找在线客服解除活动限制;
              </li>
              <li>
                <span>4.</span>
                请存款后立即领取奖励，如果在领取前发生游戏转账或投注，则会取消本次优惠资格;
              </li>
              <li>
                <span>5.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>6.</span>
                任何虚拟体育、金融投注、彩票投注将不被计算在有效投注额内;
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                若发现会员有套利、对冲或不诚实获取盈利之行为，将全额扣回红利、彩金及所产生的利润，并永久终止会员的所有权限;
              </li>
              <li>
                <span>9.</span>
                避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="tabs[tabindex].youxi"
                  @confirm="onBeforeClose"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
      tabindex: 0,
      act: 1,
      tabs: [
        {
          text: '体育',
          act: 1,
          beated: 'ty',
        },
        // {
        //   text: '电竞',
        //   act: 6,
        //   beated: 'electronic',
        // },
        {
          text: '真人',
          act: 2,
          beated: 'zr',
        },
        {
          text: '棋牌',
          act: 5,
          beated: 'qp',
        },

      ],
    }
  },
  onShow() { },

  mounted() {
    let that = this
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'ImexuBonus') {
          if (e.data.status == 1) {
            that.getdata()
          }
        }
      })
    }
  },

  methods: {
    changetabindex(index, act) {
      if (this.tabindex == index) return
      this.tabindex = index
      this.act = act
    },
    fmoney(s) {
      s = (s + '').replace(/[^\d\.-]/g, "") + "";
      var l = s.split(".")[0].split("").reverse();
      var t = "";
      for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
      }

      return t.split("").reverse().join("")
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata() {
      var _this = this
      var data = '?type=ImexuBonus'
      getdata(data).then((res) => {
        this.datalist = res.message
        this.tabs.forEach((v, i) => {
          this.tabs[i].youxi =
            v.text == '体育'
              ? res.message.youxi['ty']
              : v.text == '真人'
                ? res.message.youxi['zr']
                : v.text == '棋牌'
                  ? res.message.youxi['qp']
                  : v.text == '电竞'
                    ? res.message.youxi['dj']
                    : ''
        })
        _this.shuaxin()
      })
    },

    onBeforeClose(e) {
      console.log(e)
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'XdptBonus',
                  gameid: e.id,
                  gametype: this.tabs[this.tabindex].beated,
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 申请
          uni.postMessage({
            data: {
              urlApply: 'vipActapplyobject',
              actobject: {
                act: 'XdptBonus',
                gameid: e.id,
                gametype: this.tabs[this.tabindex].beated,
              },
              urlType: window.localStorage.getItem('urlType'),
            },
          })
        }
      })
      this.showPicker = false
    },

    applyWay() {
      this.showPicker = true
    },
    shuaxin() {
      let that = this
      window.FunctionUniapp = function (arg) {
        if (JSON.stringify(arg) == 1) {
          that.getdata()
        }
      }
    },

    cancel() {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.tab-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 auto;

  .tab-index {
    font-family: PingFang SC;
    width: 23%;
    height: 32px;
    background: #fff;
    border: 1px solid #408efe;
    border-radius: 5px;
    margin: 5px 0;
    text-align: center;
    line-height: 32px;
    color: #408efe;
    font-size: 13px;
  }

  .tabact {
    height: 32px;
    line-height: 32px;
    border: 0 !important;
    margin-top: 0px;
    box-shadow: 0px 2px 0px 0px rgba(60, 135, 254, 0.67), 0px 1px 0px 0px rgba(52, 123, 253, 0.3);
    background: linear-gradient(0deg, #2a6afd, #63c6ff) !important;
    color: #fff !important;
  }
}
.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    > div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
